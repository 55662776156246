import React, { ReactElement } from "react";
import styled from "styled-components";
import {
    onErrorImg,
    shortenNumberToStringWithComma,
} from "../../../../utils/Utils";
import { ProjectListDataType } from "../../../../query/project/useProjectListQuery";
import { Link } from "react-router-dom";
import { CommonBoxHoverEffect } from "../../commonStyle/CommonStyle";

export const ProjectListItemContainer = styled.div<{
    isMargin: boolean;
    projectBackground?: string;
}>`
    position: relative;

    width: ${({ isMargin }) => (isMargin ? `calc(100% - 24px)` : `100%`)};
    margin: ${({ isMargin }) => (isMargin ? `auto 12px` : 0)};
    height: 260px;

    background: ${({ projectBackground }) =>
        projectBackground ? projectBackground : "#F5F5F7"};
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;

    @media ${(props) => props.theme.tabletM} {
        width: ${({ isMargin }) => (isMargin ? `calc(100% - 24px)` : `100%`)};
        margin: ${({ isMargin }) => (isMargin ? `auto 12px` : 0)};
    }

    @media ${(props) => props.theme.mobileL} {
        height: 230px;

        width: ${({ isMargin }) => (isMargin ? `calc(100% - 16px)` : `100%`)};
        margin: ${({ isMargin }) => (isMargin ? `auto 8px` : 0)};

        border-radius: 16px;
    }

    transition: all ease 300ms;

    ${CommonBoxHoverEffect};
`;

export const ProjectItemListImgLink = styled(Link)`
    display: flex;
    flex-direction: column;
    height: 260px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    background: transparent;

    @media ${(props) => props.theme.mobileL} {
        height: 230px;
    }

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`;

export const ProjectListItemBannerImg = styled.img`
    background-color: transparent;
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        height: 100px;

        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
`;

export const ProjectListItemCreatorImgContainer = styled.div`
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 82px;
    width: 68px;
    height: 68px;
    border: 4px solid #f8f8f8;
    border-radius: 60px;
    overflow: hidden;
    transform: translate(-50%, 0);

    @media ${(props) => props.theme.mobileL} {
        top: 68px;
        width: 60px;
        height: 60px;
        border: 3px solid #f8f8f8;
    }

    ${ProjectListItemContainer}:hover & {
        width: 72px;
        height: 72px;
        top: 79px;
        left: 50%;
        transform: translate(-50%, 0);

        @media ${(props) => props.theme.mobileL} {
            width: 64px;
            height: 64px;
            top: 66px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    &::after {
        display: block;
        content: "";
        padding-bottom: 100%;
    }

    transition: all ease 300ms;
`;

export const ProjectListItemCreatorImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
`;

export const ProjectListItemInfoContainer = styled.div`
    position: relative;
    height: 140px;
    text-align: center;

    @media ${(props) => props.theme.mobileL} {
        height: 120px;
    }
`;

export const ProjectListItemInfoProjectName = styled.div`
    font-weight: 700;
    font-size: 22px;
    line-height: 1;
    padding-bottom: 3px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 44px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;

    @media ${(props) => props.theme.mobileL} {
        font-size: 16px;
        margin-top: 44px;
        padding-bottom: 2px;
    }
`;

export const ProjectListItemInfoDesc = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;

    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: #555555;
    gap: 6px;

    @media ${(props) => props.theme.mobileL} {
        flex-direction: column;
        gap: 8px;
        margin-top: 14px;
        font-size: 12px;
        line-height: 100%;
    }
`;

export const ProjectListItemInfoDescItemCount = styled.div``;

export const ProjectListItemInfoDescItemDot = styled.span`
    color: #c8c8c8;
    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const ProjectListItemInfoItemLikeCount = styled.div``;

type Props = {
    info: ProjectListDataType;
    isMargin: boolean;
    isDragging?: boolean;
    projectBackground?: string;
};

const ProjectListItem = (props: Props): ReactElement | null => {
    console.log(props.projectBackground);
    return (
        <ProjectListItemContainer
            isMargin={props.isMargin}
            projectBackground={props.projectBackground}
        >
            <ProjectItemListImgLink
                to={
                    typeof props.isDragging !== "undefined"
                        ? !props.isDragging
                            ? "/market/" + props.info.project_idx
                            : {}
                        : "/market/" + props.info.project_idx
                }
                onClick={(e) => {
                    if (
                        typeof props.isDragging !== "undefined" &&
                        props.isDragging
                    ) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                <ProjectListItemBannerImg
                    src={props.info.project_banner_full_path}
                    onError={onErrorImg}
                />
                <ProjectListItemInfoContainer>
                    <ProjectListItemInfoProjectName>
                        {props.info.project_name}
                    </ProjectListItemInfoProjectName>
                    <ProjectListItemInfoDesc>
                        <ProjectListItemInfoDescItemCount>
                            아이템{" "}
                            {shortenNumberToStringWithComma(
                                props.info.item_cnt,
                            )}
                            종
                        </ProjectListItemInfoDescItemCount>
                        <ProjectListItemInfoDescItemDot>
                            ·
                        </ProjectListItemInfoDescItemDot>
                        <ProjectListItemInfoItemLikeCount>
                            좋아요{" "}
                            {shortenNumberToStringWithComma(
                                props.info.like_cnt,
                            )}
                            개
                        </ProjectListItemInfoItemLikeCount>
                    </ProjectListItemInfoDesc>
                </ProjectListItemInfoContainer>
                <ProjectListItemCreatorImgContainer>
                    <ProjectListItemCreatorImg
                        src={props.info.project_thumbnail_full_path}
                        onError={onErrorImg}
                    />
                </ProjectListItemCreatorImgContainer>
            </ProjectItemListImgLink>
        </ProjectListItemContainer>
    );
};

export default ProjectListItem;
