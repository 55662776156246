import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { ItemListItemType } from "../../../../query/item/useItemListQuery";
import { useTransferItemMutation } from "../../../../query/item/transfer/useTransferItemMutation";
import { ModalButton, ModalButtonContainer, ModalTitle } from "../ModalStyle";
import ModalCloseIcon from "../modalCloseIcon/ModalCloseIcon";
import { useGetOwnItemCnt } from "../../../../query/item/useGetOwnItemCnt";
import { useOpenCommonErrorModal } from "../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import Spinner from "../../commonUnitComponent/spinner/Spinner";
import ItemTransferUseModalItemInfo from "./components/ItemTransferUseModalItemInfo";
import ItemTransferTargetInput from "./components/ItemTransferTargetInput";
import ItemTransferCountInput from "./components/ItemTransferCountInput";
import ItemTransferWarningList from "./components/ItemTransferWarningList";
import Scrollbar from "../../commonUiComponent/scrollbar/Scrollbar";
import { SCROLL_BAR_NORMAL_TYPE } from "../../commonUiComponent/scrollbar/ScrollbarType";
import ItemTransferAdSaleInfo from "./components/ItemTransferAdSaleInfo";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";

export const ItemTransferModalWrapper = styled.div`
    width: 352px;
    margin: 0;
    cursor: auto;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const ItemTransferModalScrollWrapper = styled.div`
    margin-top: 28px;
    position: relative;
    max-height: 444px;
`;

export const ItemTransferModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
`;

type Props = {
    resolve: (value: boolean | PathAfterResolveType) => void;
    item: ItemListItemType;
};

const ItemTransferModal = (props: Props): ReactElement | null => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const {
        data: rawOwnItemCnt,
        refetch: refetchOwnItemCnt,
        isSuccess: isOwnItemCntSuccess,
    } = useGetOwnItemCnt(props.item.item_idx);
    const [isPending, setIsPending] = useState(false);

    const [inputNumber, setInputNumber] = useState(0);
    const warningList = [
        "수신인의 닉네임은 세이버즈 닉네임으로 표시됩니다.",
        "전송한 아이템은 되돌릴 수 없습니다. 수신인의 아이디와 닉네임을 다시 한번 확인해 주세요.",
        "귀속아이템은 전송후 수신인에게 귀속됩니다.",
    ];

    const [selectedUserInfo, setSelectedUserInfo] = useState({
        user_id: "",
        user_idx: "-1",
        user_nick: "",
    });

    const { mutateAsync: transferItem } = useTransferItemMutation(
        props.item.item_idx,
        {
            user_idx: selectedUserInfo.user_idx,
            count: inputNumber,
        },
    );

    const checkOwnItemCnt = async () => {
        await openCommonErrorModal({
            title: "전송 가능한 수량이 없습니다.",
        });

        props.resolve(false);
    };

    useEffect(() => {
        if (isOwnItemCntSuccess && rawOwnItemCnt.own_item_cnt === 0) {
            checkOwnItemCnt();
        }
    }, [isOwnItemCntSuccess, rawOwnItemCnt]);

    const isTransferAble =
        inputNumber !== 0 &&
        selectedUserInfo.user_idx !== "-1" &&
        (rawOwnItemCnt.ad_sell_bool ||
            (!rawOwnItemCnt.ad_sell_bool &&
                rawOwnItemCnt.daily_transfer_remaining_cnt > 0));

    return (
        <ItemTransferModalWrapper>
            <ModalTitle>아이템 전송</ModalTitle>
            <ModalCloseIcon resolve={props.resolve} />

            <ItemTransferModalScrollWrapper>
                <Scrollbar
                    type={SCROLL_BAR_NORMAL_TYPE}
                    id={"item-transfer-modal"}
                >
                    <ItemTransferModalContainer>
                        <ItemTransferUseModalItemInfo
                            thumbnailPath={props.item.item_thumbnail_full_path}
                            itemName={props.item.item_name}
                            projectName={props.item.project_name}
                        />

                        <ItemTransferTargetInput
                            selectedUserInfo={selectedUserInfo}
                            setSelectedUserInfo={setSelectedUserInfo}
                        />

                        <ItemTransferCountInput
                            count={inputNumber}
                            setCount={setInputNumber}
                            maxCount={rawOwnItemCnt.own_item_cnt}
                            refetchMaxCount={refetchOwnItemCnt}
                            title={"전송"}
                        />

                        <ItemTransferAdSaleInfo
                            resolve={props.resolve}
                            isAdSale={rawOwnItemCnt.ad_sell_bool}
                            maxCount={rawOwnItemCnt.daily_transfer_max_cnt}
                            remainingCount={
                                rawOwnItemCnt.daily_transfer_remaining_cnt
                            }
                        />

                        <ItemTransferWarningList warningList={warningList} />
                    </ItemTransferModalContainer>
                </Scrollbar>
            </ItemTransferModalScrollWrapper>

            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    취소
                </ModalButton>
                <ModalButton
                    btnType={
                        isTransferAble
                            ? isPending
                                ? "filled-primary-pending"
                                : "filled-primary"
                            : "filled-primary-disabled"
                    }
                    onClick={async () => {
                        if (isTransferAble && !isPending) {
                            try {
                                setIsPending(true);
                                const result = await transferItem();
                                props.resolve(true);
                                // 실패하던 성공하던 닫기 때문에 확실히 pending 유지 시킨다.
                            } catch (e: any) {
                                props.resolve(false);
                            }
                        }
                    }}
                >
                    {isPending ? (
                        <Spinner
                            width={28}
                            height={28}
                            borderSize={3}
                            color={"#fa54fa #fa54fa #fa54fa #f0f0f2"}
                        />
                    ) : (
                        "전송하기"
                    )}
                </ModalButton>
            </ModalButtonContainer>
        </ItemTransferModalWrapper>
    );
};

export default ItemTransferModal;
