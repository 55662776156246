import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { useAdPublicTemplateInfoQuery } from "../../../../../../../../query/ad/publicTemplate/useAdPublicTemplateInfoQuery";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../../../../common/commonUiComponent/scrollbar/ScrollbarType";
import Scrollbar from "../../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import viewHeightAtom from "../../../../../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import TemplateInfoModalTemplateInfo from "../../../../../../../common/modal/templateInfoModal/components/templateInfoModalContents/components/TemplateInfoModalTemplateInfo";
import TemplateInfoModalTemplateSpaceInfo from "../../../../../../../common/modal/templateInfoModal/components/templateInfoModalContents/components/templateInfoModalTemplateSpaceInfo/TemplateInfoModalTemplateSpaceInfo";
import { ModalButton } from "../../../../../../../common/modal/ModalStyle";
import { TemplateInfoType } from "../../../../../../../../types/ad/templateInfo/TemplateInfoType";
import { useAdMyTemplateInfoQuery } from "../../../../../../../../query/ad/myTemplate/useAdMyTemplateInfoQuery";
import { PathAfterResolveType } from "../../../../../../../../hooks/modal/useOpenModal";
import { useOpenCommonErrorModal } from "../../../../../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { queryClient } from "../../../../../../../../App";
import { Query } from "react-query";

export const AdBidingSelectTemplatePreviewWrapper = styled.div<{
    isShow: boolean;
}>`
    top: 0;
    left: ${({ isShow }) => (isShow ? 0 : "-100%")};
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
    transition: all 300ms ease;
    box-sizing: border-box;
    padding-top: 24px;
    position: absolute;
    background: white;
    border-radius: 16px;
    z-index: 2;
    width: 100%;
    overflow: hidden;

    @media ${(props) => props.theme.mobileL} {
        padding: 0;
    }
`;

export const AdBidingSelectTemplatePreviewHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 24px 16px 24px;

    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 100% */

    @media ${(props) => props.theme.mobileL} {
        padding: 0 16px 16px 16px;
    }
`;

export const AdBidingSelectTemplatePreviewHeaderBackIcon = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

export const AdBidingSelectTemplatePreviewScrollWrapper = styled.div<{
    vh: number;
}>`
    position: relative;
    height: ${({ vh }) => "calc(" + 100 * vh + "px - 294px)"};
    max-height: 537px;

    @media ${(props) => props.theme.mobileL} {
        max-height: 557px;
    }
`;

export const AdBidingSelectTemplatePreviewContainer = styled.div`
    padding-top: 8px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        gap: 16px;
        padding-bottom: 16px;
    }
`;

export const AdBidingSelectPublicTemplateBtnContainer = styled.div`
    padding: 24px;
    display: flex;
    gap: 10px;
    border-top: 1px #ebebeb solid;

    @media ${(props) => props.theme.mobileL} {
        padding: 16px;
    }
`;

type Props = {
    selectedPreviewTemplateIdx: string;
    setSelectedPreviewTemplateIdx: (value: string) => void;
    selectTemplate: (templateInfo: TemplateInfoType) => void;
    isPublicTemplateTab: boolean;
    resolve: (value: PathAfterResolveType | boolean) => void;
};

const AdBidingSelectTemplatePreview = (props: Props): ReactElement | null => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const {
        data: rawPublicTemplateData,
        error: publicTemplateDataError,
        isError: isPublicTemplateDataError,
    } = useAdPublicTemplateInfoQuery(props.selectedPreviewTemplateIdx, {
        enabled:
            props.selectedPreviewTemplateIdx !== "-1" &&
            props.isPublicTemplateTab,
        staleTime: 500,
        cacheTime: 60000,
    });

    const { data: rawMyTemplateData } = useAdMyTemplateInfoQuery(
        props.selectedPreviewTemplateIdx,
        props.selectedPreviewTemplateIdx !== "-1" && !props.isPublicTemplateTab,
    );

    const templateData = props.isPublicTemplateTab
        ? rawPublicTemplateData
        : rawMyTemplateData;

    const [vh] = useAtom(viewHeightAtom);
    const templateInfo = templateData.templateInfo;

    useEffect(() => {
        if (isPublicTemplateDataError) {
            queryClient.refetchQueries({
                predicate: (query: Query) =>
                    query.queryKey.includes(`ad-public-template-list`),
            });

            const typeChangedError = publicTemplateDataError as any;
            try {
                openCommonErrorModal({
                    title: typeChangedError.response.data.message,
                });
            } catch (e) {
                openCommonErrorModal({
                    title: "오류가 발생하였습니다.",
                });
            }

            props.setSelectedPreviewTemplateIdx("-1");
        }
    }, [isPublicTemplateDataError]);

    return (
        <AdBidingSelectTemplatePreviewWrapper
            isShow={props.selectedPreviewTemplateIdx !== "-1"}
        >
            <AdBidingSelectTemplatePreviewHeader>
                <AdBidingSelectTemplatePreviewHeaderBackIcon
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-left.svg`}
                    onClick={() => {
                        props.setSelectedPreviewTemplateIdx("-1");
                    }}
                />
                {templateData.templateInfo.template_name}
            </AdBidingSelectTemplatePreviewHeader>

            <AdBidingSelectTemplatePreviewScrollWrapper vh={vh}>
                <Scrollbar
                    id={"ad-biding-select-template-modal-template-preview"}
                    type={SCROLL_BAR_NORMAL_TYPE}
                    isThin={true}
                >
                    <AdBidingSelectTemplatePreviewContainer>
                        <TemplateInfoModalTemplateInfo
                            templateInfo={templateInfo}
                            resolve={props.resolve}
                        />
                        <TemplateInfoModalTemplateSpaceInfo
                            templateInfo={templateInfo}
                        />
                    </AdBidingSelectTemplatePreviewContainer>
                </Scrollbar>
            </AdBidingSelectTemplatePreviewScrollWrapper>

            <AdBidingSelectPublicTemplateBtnContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve({
                            pathAfterResolve: `/ad/template-copy/${templateInfo.template_idx}`,
                        });
                    }}
                >
                    템플릿 복제 및 수정
                </ModalButton>
                <ModalButton
                    btnType={"filled-primary"}
                    onClick={() => {
                        props.selectTemplate(templateInfo);
                    }}
                >
                    템플릿 선택
                </ModalButton>
            </AdBidingSelectPublicTemplateBtnContainer>
        </AdBidingSelectTemplatePreviewWrapper>
    );
};

export default AdBidingSelectTemplatePreview;
