import { useInfiniteQuery } from "react-query";
import { ItemApi } from "../../apis/ItemApi";
import { COMMON_QUERY_PARAMS } from "../../types/BasicType";

export type ItemListApiResultType = {
    itemList: ItemListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type ItemListItemType = {
    item_idx: string;
    item_name: string;
    tokenId: string;
    item_thumbnail_full_path: string;
    brand_thumbnail_full_path: string;
    user_thumbnail_full_path: string;
    item_status: string;
    like_bool: boolean;
    like_cnt: number;
    inventory_type: string;
    last_transaction_price: number;
    project_currency: string;
    project_currency_local: string;
    project_idx: string;
    project_name: string;
    title_bool: boolean;
    project_network: string;
    private_bool: boolean;
    own_cnt: number;
    low_price: number;
};

export type ItemListApiParamsType = {
    list_type?: string;
    sort_type: string;
    page_no: number;
    limit: number;
    daysOffset?: number;

    item_name?: string;

    project_idx?: string;
    user_idx?: string;
    not_item_idx?: string; // 제외할 item_idx

    item_price?: string;
    item_status?: string;
    transaction_bool?: string;
    project_erc?: string;
    project_network?: string;
    category_no?: string;
    brand_id?: string;

    item_inventory_group_idx?: string;
    item_group_depth?: string;
};

export const useItemListQuery = (
    params: ItemListApiParamsType,
    queryParam: COMMON_QUERY_PARAMS = {
        cacheTime: 600000,
        staleTime: 5000,
        enabled: true,
    },
) => {
    const queryResult = useInfiniteQuery<ItemListApiResultType, Error>({
        queryKey: [
            "item-list-" + JSON.stringify(params),
            "item-list",
            "item-info-refresh-target",
        ],
        queryFn: ({ pageParam = 1 }) =>
            ItemApi.getList({
                ...params,
                page_no: pageParam,
            }),
        refetchOnWindowFocus: false,
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
        ...queryParam,
    });

    const defaultData: ItemListApiResultType[] = [
        {
            itemList: [],
            totalCount: 0,
        },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadItemListPages(pages: ItemListApiResultType[]) {
    return pages
        .map((result) => result.itemList)
        .reduce((prev, next) => prev.concat(next));
}
