import { useEffect } from "react";
import { useTempIsLogin } from "../useTempIsLogin";

export function useDevRouterGuard() {
    const isTempLogin = useTempIsLogin();

    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            if (!isTempLogin && location.pathname !== "/temp-login") {
                location.href = "/temp-login";
            }
        }
    }, []);
}
