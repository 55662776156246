import { ReactElement } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../commonStyle/CommonButtonStyle";
import { PathAfterResolveType } from "../../../../../hooks/modal/useOpenModal";

export const ItemTransferAdSaleInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ItemTransferAdSaleInfoCountContainer = styled.div`
    display: flex;
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    gap: 4px;
`;

export const ItemTransferAdSaleInfoCountKey = styled.div``;

export const ItemTransferAdSaleInfoCountValue = styled.div`
    display: flex;
    gap: 2px;
    color: #fa54fa;
`;

export const ItemTransferAdSaleInfoCountValueUnit = styled.div`
    color: #222;
`;

export const ItemTransferAdSaleInfoContainer = styled.div`
    display: flex;
    padding: 12px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
`;

export const ItemTransferAdSaleInfoTitle = styled.div`
    color: #2e8eff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
`;

export const ItemTransferAdSaleInfoDescContainer = styled.div`
    margin-top: -8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ItemTransferAdSaleInfoDesc = styled.div`
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
`;

export const ItemTransferAdSaleInfoBtnContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 6px;
`;

export const ItemTransferAdSaleInfoBtnLink = styled(Link)`
    width: 100%;
`;

export const ItemTransferAdSaleInfoBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    font-size: 15px;
    height: 40px;
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    isAdSale: boolean;
    maxCount: number;
    remainingCount: number;
};

const ItemTransferAdSaleInfo = (props: Props): ReactElement | null => {
    return !props.isAdSale ? (
        <ItemTransferAdSaleInfoWrapper>
            <ItemTransferAdSaleInfoCountContainer>
                <ItemTransferAdSaleInfoCountKey>
                    오늘 전송 가능 횟수 :{" "}
                </ItemTransferAdSaleInfoCountKey>
                <ItemTransferAdSaleInfoCountValue>
                    {props.remainingCount}
                    <ItemTransferAdSaleInfoCountValueUnit>
                        회
                    </ItemTransferAdSaleInfoCountValueUnit>
                </ItemTransferAdSaleInfoCountValue>
            </ItemTransferAdSaleInfoCountContainer>

            <ItemTransferAdSaleInfoContainer>
                <ItemTransferAdSaleInfoTitle>
                    내 광고를 판매등록하고,
                    <br />
                    횟수제한 없이 아이템을 전송해보세요!
                </ItemTransferAdSaleInfoTitle>
                <ItemTransferAdSaleInfoDescContainer>
                    <ItemTransferAdSaleInfoDesc>
                        · 판매등록 하지 않은 계정은 일일 최대 {props.maxCount}회
                        전송이 가능합니다.
                    </ItemTransferAdSaleInfoDesc>
                    <ItemTransferAdSaleInfoDesc>
                        · 판매등록 후 판매등록을 철회하면 횟수제한이 다시
                        적용됩니다.
                    </ItemTransferAdSaleInfoDesc>
                </ItemTransferAdSaleInfoDescContainer>

                <ItemTransferAdSaleInfoBtnContainer>
                    <ItemTransferAdSaleInfoBtn
                        btnType={"filled-secondary"}
                        onClick={() => {
                            window.open(
                                "//about.saybuzz.io/ko/guide/ad/registration",
                                "_blank",
                            );
                        }}
                    >
                        가이드 보기
                    </ItemTransferAdSaleInfoBtn>

                    <ItemTransferAdSaleInfoBtn
                        btnType={"filled-primary-light"}
                        onClick={() => {
                            props.resolve({
                                pathAfterResolve: "/ad/register-helper",
                            });
                        }}
                    >
                        광고 판매등록하기
                    </ItemTransferAdSaleInfoBtn>
                </ItemTransferAdSaleInfoBtnContainer>
            </ItemTransferAdSaleInfoContainer>
        </ItemTransferAdSaleInfoWrapper>
    ) : null;
};

export default ItemTransferAdSaleInfo;
